// YourParentComponent.jsx
import React from 'react';
import Table from '../Components/Table';
import HomeLink from '../Components/HomeLink';

const Music = () => {
  const tableData = [
    { artist: ['green-milk'], title: "you've barely touched your beet salad", date: '2024-03-14', info: 'made using ableton, surge xt, polymax synth', links: [{local: "beets.wav"}] },
    { artist: ['green-milk'], title: '20240306', date: '2024-03-06', info: 'made using ableton, surge xt, polymax synth', links: [{local: "20240306.wav"}] },
    { artist: ['green-milk'], title: 'suds', date: '2024-02-29', info: 'made using ableton, rnbo, polymax synth', links: [{local: "suds.wav"}]},
    { artist: ['green-milk'], title: 'tearing my acl on the 38R', date: '2024-02-13', info: 'made using ableton, rnbo', links: [{patch: "/patches"}, {local: "tearing my acl on the 38R.wav"}]},
    { artist: ['green-milk', 'josh'], title: 'bedroom jam', date: '2024-02-04', info: 'made using ableton, microkorg, stratocaster ', links: [{local: "bedroom jam.wav"}] },
    { artist: ['green-milk', 'dj fruit rollup', 'austin'], title: 'fainting at the ballet', date: '2024-01-27', info: 'made using ableton, microkorg, blue snowball, polymax synth', links: [{soundcloud: "https://soundcloud.com/green_milk/fainting-at-the-ballet"}] },
    { artist: ['green-milk'], title: 'something sticky', date: '2024-01-23', info: 'made using ableton, microkorg, polymax synth', links: [{local: "something sticky.wav"}] },
    { artist: ['green-milk'], title: 'plums', date: '2024-01-02', info: 'made using ableton, microkorg, roland TR-909, polymax synth', links: [{soundcloud:"https://soundcloud.com/green_milk/plums"}] },
    { artist: ['green-milk', 'josh'], title: 'sitting on the floor', date: '2023-11-23', info: 'recorded with voice memos. stratocaster, moog rogue (1981)', links: [{local: "sitting on the floor.wav"}] },
    { artist: ['green-milk', 'dj fruit rollup'], title: 'alcoholic grape juice', date: '2023-10-16', info: 'made using ableton, microkorg, polymax synth', links: [{local: "alcoholic grape juice.wav"}] },
    { artist: ['green-milk'], title: 'clean your ears', date: '2023-10-10', info: 'made using ableton, microkorg, polymax synth', links: [{moreInfo: "/clean-your-ears"}, {local: "clean-your-ears.wav"}]},
    
  ]

  return (
   <>
      <div className="buffer">
        <center>
          <a href="/">green milk </a>
        </center>
        </div>
      <Table data={tableData} />
   </>
  );
};

export default Music;
