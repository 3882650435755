import React from 'react';
import PhotoGallery from '../Components/Photo Gallery/PhotoGallery';
import AudioPlayer from '../Components/Audio Player/AudioPlayer';
import HomeLink from '../Components/HomeLink';

const CleanYourEars = () => {
    const publicURL = process.env.PUBLIC_URL;
    const photos = [
      { url: publicURL + "destruction.jpg" },
      // { url: imageUrl + "construction.jpg"  },
      // { url: imageUrl + "altar.jpg"  },
      { url: publicURL + "manifesto.jpg"  },
      // Add more photo objects as needed
    ];
  return (
    <div style={{backgroundColor:'white'}}>
    <div className='buffer'>
    <center><a href="/">green milk </a></center>
    <div className="gallery-container">
    <div className="buffer">
    <PhotoGallery photos={photos}/>
    <div className='audio-desc'>this song might clean your ears. if it doesn't go see your otolaryngologist. they probably miss you</div>
    <AudioPlayer audioUrl={publicURL + "clean-your-ears.wav"}/>
    </div>
    </div>
    </div>
    </div>
  );
};

export default CleanYourEars;
