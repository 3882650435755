import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Container from './Components/Container'
import HomePage from './Pages/Home';
import FourOhFour from './Pages/FourOhFour';
import PhotoGallery from './Components/Photo Gallery/PhotoGallery';
import CleanYourEars from './Pages/CleanYourEars';
import Music from './Pages/Music';
import AudioVisualizerPage from './Pages/AudioVisualizer';
import Patches from './Pages/Patches/Patches';
import Synth from './Pages/Patches/Synth';
import Ringtones from './Pages/Ringtones';
function App() {
  return (
    <Router>
    <div className="App">
    <Routes>
      <Route 
        path="/"
        element={<HomePage/>}
      />
      <Route
      path="/clean-your-ears"
      element={<CleanYourEars/>}
      />
      <Route
        path="/music"
        element={<Music/>}
      />
      <Route
        path="/patches"
        element={<Patches/>}
        />
        <Route
        path="/ringtones"
        element={<Ringtones/>}
        />
      <Route
        path="*"
        element={<FourOhFour/>}
      />
    </Routes>
   
    </div>
    </Router>
  );
}

export default App;