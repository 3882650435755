  import React from 'react';
  import PhotoGallery from '../Components/Photo Gallery/PhotoGallery';
  import AudioPlayer from '../Components/Audio Player/AudioPlayer';
  import { Link } from 'react-router-dom';
  
  const HomeLink = () => {
    return (
      <center>
      <table>
        <tr>
        <Link to={"/"} style={{justifyContent:'center'}}>
        green milk
      </Link>
        </tr>
      </table>
      </center>
    );
  };
  
  export default HomeLink;
  