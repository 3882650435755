import React from "react";
import PhotoGallery from "../Components/Photo Gallery/PhotoGallery";
import AudioPlayer from "../Components/Audio Player/AudioPlayer";
import HomeLink from "../Components/HomeLink";

const Ringtones = () => {
  const publicURL = process.env.PUBLIC_URL;
  const photos = [];
  return (
    <div>
      <div className="buffer">
        <center>
          <a href="/">green milk </a>
        <div className="ascii-art">
        <br/>
        | `--.___,-._      ; `--.___,-._      | `--.___,-._      ; `--.<br/>
 ,-.' `.  /  _,'O ) ,-.' `.  /  _,'O ) ,-.' `.  /  _,'O ) ,-.' `.  /<br/>
 |__.-. `' ,'    /  |__.-. `' ,'    /  |__.-. `' ,'    /  |__.-. `'<br/>
_,--. |  ,'     (___,--. |  ,'     (___,--. |  ,'     (___,--. |  ,'<br/>
_,--' |      _,-.___,--' |      _,-.___,--' |      _,-.___,--' |<br/>
 \  ,' \,-. ( O`._  \  ,' `,-. ( O`._  \  ,' \,-. ( O`._  \  ,' `,-.<br/>
. \' .-.__|  \    `. `' .-.__|  \    `. `' .-.__|  \    `. `' .-.__|<br/>
 `.  | ,--.___)     `.  | ,--.___)     `.  | ,--.___)     `.  | ,--.<br/>
     | `--.___,-._      | `--.___,-._      | `--.___,-._      | `--.<br/>
 ,-./ `.  /  _,'O ) ,-.' `.  /  _,'O ) ,-./ `.  /  _,'O ) ,-.' `.  /<br/>
 |__.-. `' ,'    /  |__.-. `' ,'    /  |__.-. `' ,'    /  |__.-. `'<br/>
_,--. |  ,'     (___,--. |  ,'     (___,--. |  ,'     (___,--. |  ,'<br/>
_,--' |      _,-.___,--' |      _,-.___,--' |      _,-.___,--' |<br/>
 \  ,' `,-. ( O`._  \  ,' \,-. ( O`._  \  ,' `,-. ( O`._  \  ,' \,-.<br/>
. `' .-.__|  \    `. `' .-.__|  \    `. `' .-.__|  \    `. `' .-.__|<br/>
 `.  | ,--.___)     `.  | ,--.___)     `.  | ,--.___)     `.  | ,--.<br/>
     | `--.___,-._      | `--.___,-._      | `--.___,-._      | `--.<br/>
 ,-.' `.  /  _,'O ) ,-.' `.  /  _,'O ) ,-.' `.  /  _,'O ) ,-.' `.  /<br/>
 |__.-. `' ,'    /  |__.-. `' ,'    /  |__.-. `' ,'    /  |__.-. `'<br/>
_,--. |  ,'     (___,--. |  ,'     (___,--. |  ,'-hi-(___,--. |  ,'<br/>
_,--' |      _,-.___,--' |      _,-.___,--' |      _,-.___,--' |<br/>
 \  ,' \,-. ( O`._  \  ,' `,-. ( O`._  \  ,' \,-. ( O`._  \  ,' `,-.<br/>
. `' .-.__|  \    `. `' .-.__|  \    `. `' .-.__|  \    `. `' .-.__|<br/>
 `.  | ,--.___)     `.  | ,--.___)     `.  | ,--.___)     `.  | ,--.<br/>
</div>
          <div className="buffer">
            <div className="audio-desc">
            <p>
              made using Buchla easel v
              </p>
              <a href={process.env.PUBLIC_URL + "momscalling.wav"} download={process.env.PUBLIC_URL + "momscalling.wav"} style={{ textDecoration: 'none', fontSize: 16}}>
           download 📲
            </a>
            </div>
            <AudioPlayer audioUrl={publicURL + "momscalling.wav"} />
            
          </div>
          </center>
      </div>
    </div>
  );
};

export default Ringtones;
