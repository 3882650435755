import React from 'react';

const PictureWithLink = ({ imageUrl, linkUrl }) => { 
    return (
  <div>
  <a href={linkUrl} target="_blank" rel="noopener noreferrer">
    <img src={imageUrl} style={{ width: '25%', height: '10%'}}/>
    </a>
    <p>
    </p>
  </div>
);
};
export default PictureWithLink;