// YourTableComponent.jsx
import React from 'react';
import './Table.css'; // Import the CSS file for styling

const YourTableComponent = ({ data }) => {
  return ( 
    <div style={{backgroundColor:'#deedd3'}}>
      <p></p>
    <div className="table-container">
      <table className="dynamic-width-table">
        <thead>
          <tr>
            <th>artist(s)</th>
            <th>title</th>
            <th>release date</th>
            <th>info/resources</th>
            <th>links</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={index}>
               <td>
                {item.artist.map((artist, artistIndex) => (
                  <span key={artistIndex}>
                    {artistIndex > 0 && ', '}
                    {artist}
                  </span>
                ))}
              </td>
              <td>{item.title}</td>
              <td>{item.date}</td>
              <td>{item.info}</td>
              <td>
                  {item.links.map((link, linkIndex) => (
                    <span key={linkIndex} style={{textDecoration: 'none'}}>
                      {linkIndex > 0 && ' '}
                      {renderLinkEmoji(link)}
                    </span>
                  ))}
                </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div style={{textAlign:'center'}}>
        <p></p>
      KEY: [❓]=More Info, [☁️]=SoundCloud, [🆓]=Free WAV Download [🧶]=Patch Info/Download
      </div>
    </div>
    </div>
  );
};
const renderLinkEmoji = (link) => {
   if (link.moreInfo) {
    return (
    <a href={link.moreInfo} style={{ textDecoration: 'none' }}>❓</a>
    );
  }
  else if (link.patch) {
    return (
      <a href={link.patch} style={{ textDecoration: 'none' }}>🧶</a>
      );
  }
  else if (link.soundcloud) {
    return (
      <a href={link.soundcloud} target="_blank" style={{ textDecoration: 'none' }}>☁️</a>
    );
  } else if (link.local) {
    return (
        <a href={link.local} download={process.env.PUBLIC_URL + link.local} style={{ textDecoration: 'none' }}>
          🆓
        </a>
    );
  } 
};
export default YourTableComponent;
