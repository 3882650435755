import React from 'react';
import './PhotoGallery.css'; // Import the CSS file for styling

const PhotoGallery = ({ photos }) => {
  return (
    <div className="container">
      {photos.map((photo, index) => (
        <div key={index} className="photo-item">
          <img src={photo.url}/>
        </div>
      ))}
    </div>
  );
};

export default PhotoGallery;