import React from 'react';
import { Link } from 'react-router-dom';

const Container = ({ title, linkTo }) => {
  return (
    <div className="buffer">
      <Link to={linkTo} className="rounded-link">{title}</Link>
    </div>
  );
};

export default Container;
