import { createDevice, MIDIEvent, scheduleEvent } from "@rnbo/js";

let WAContext = window.AudioContext || window.webkitAudioContext;
let context = new WAContext();
let device;
export const setup = async () => {
    const outputNode = context.createGain();
    outputNode.connect(context.destination);
    let rawPatcher = await fetch(process.env.PUBLIC_URL + "/export/synth.export.json");
    let patcher = await rawPatcher.json();

    device = await createDevice({ context, patcher });
    console.log(device)
    console.log(context)
    getParams();
    // This connects the device to audio output, but you may still need to call context.resume()
    // from a user-initiated function.
    device.node.connect(outputNode);
    return [device, context];
};
export const getParams = () => {
    if (device !== null) {
        device.parameters.forEach(parameter => {
            console.log(parameter.name)
        })
    }
}

export function noteOn(rnboDevice, context, pitch, velocity) {
    let midiChannel = 0;
    let midiPort = 0;

    // Format a MIDI message payload, this constructs a MIDI note-on event
    let noteOnMessage = [
        144 + midiChannel, // Code for a note on: 10010000 & MIDI channel (0-15)
        pitch, // MIDI Note
        velocity // MIDI Velocity
    ];

    // When scheduling an event, use the current audio context time
    // multiplied by 1000 (converting seconds to milliseconds)
    let noteOnEvent = new MIDIEvent(context.currentTime * 1000, midiPort, noteOnMessage);

    rnboDevice.scheduleEvent(noteOnEvent);
}

export function noteOff(rnboDevice, context, pitch) {
    let midiChannel = 0;
    let midiPort = 0;
    console.log("NOTE OFF")
    // Format a MIDI message payload, this constructs a MIDI note-off event
    let noteOffMessage = [
        128 + midiChannel, // Code for a note on: 10010000 & MIDI channel (0-15)
        pitch, // MIDI Note
        0 // MIDI Velocity is 0 for a note off
    ];

    // When scheduling an event, use the current audio context time
    // multiplied by 1000 (converting seconds to milliseconds)
    let noteOffEvent = new MIDIEvent(context.currentTime * 1000, midiPort, noteOffMessage);

    rnboDevice.scheduleEvent(noteOffEvent);
}
// We can't await an asynchronous function at the top level, so we create an asynchronous
// function setup, and then call it without waiting for the result.
