import React from 'react';
import { Link } from 'react-router-dom';
import PictureWithLink from '../Components/PictureWithLink';
import HomeLink from '../Components/HomeLink';

const FourOhFour = () => {
  const imageUrl = process.env.PUBLIC_URL + '/FourOhFour.jpeg';
  return (
    <div className="container">
    <div className="buffer">
    <p></p>
    <p></p>
    <PictureWithLink 
      imageUrl={imageUrl}
      linkUrl={"https://www.youtube.com/watch?v=eYZ-ix68Zeo&list=PLR10lMeWcg6Tr34HU7pENdT5StLos_R-2"}
    />
    <p></p>
    <div>
      <>You are free and that is why you are lost.</>
      </div>
    </div>
    <HomeLink/>
    </div>
  );
};

export default FourOhFour;
