import React, { useEffect, useRef, useMemo } from 'react';
import p5 from 'p5';
import { setup, noteOn, noteOff } from './rnbo-helpers';
import HomeLink from '../../Components/HomeLink';
const Patches = () => {
  const p5Canvas = useRef(null);
  let device;
  let context;
  let note = undefined;
  let isNotePlaying = false;
  let mouseDownX = 0;
  let mouseDownY = 0;
  let notes = [52, 64, 76, 49, 61, 73, 54, 66, 78, 56, 68, 80, 47, 59, 71, 83]
  useEffect(() => {
    async function setupRNBO() {
      [device, context] = await setup();
    }

    setupRNBO();
  }, []);

  const sketch = (p) => {
    p.setup = () => {
      // Set canvas size based on the device dimensions
      p.createCanvas(window.innerWidth - 30, window.innerHeight - 100).center('horizontal').position(15, 90);
      p.colorMode(p.HSB, 255);
    };

    p.draw = () => {
      // Visualize canvas boundaries with a border
      p.stroke(0);
      p.noFill();
      p.rect(0, 0, p.width, p.height);
      if (device) {
        const mouseDelta = Math.abs(p.mouseX - mouseDownX);
        const mouseDeltaNormalized = p.map(mouseDelta, 0, p.width / 2, 0, 10);
        const tremolo = device.parametersById.get("freq");
        tremolo.normalizedValue = mouseDeltaNormalized;
      }
      if (p.mouseIsPressed) {
        // Change color based on a variable
        p.fill(p.map(note, 50, 70, 0, 255), 255, 255, 128);
        // For random colors, you can use the following line instead:
        // p.fill(p.random(255), p.random(255), p.random(255));
        mouseDownX = p.mouseX
        // Adjust ellipse size based on the device dimensions
        const ellipseSize = p.map(p.width, 0, p.windowWidth, 10, 50);
        p.ellipse(p.mouseX, p.mouseY, ellipseSize, ellipseSize);
      }
    };

    p.mousePressed = () => {
      // Check if mouse is pressed inside the canvas
      if (
        p.mouseX >= 0 &&
        p.mouseX <= p.width &&
        p.mouseY >= 0 &&
        p.mouseY <= p.height
      ) {
        if (device && !isNotePlaying) {
          context.resume();
          note = notes[Math.floor(Math.random()*notes.length)]
          noteOn(device, context, note, 100);
          isNotePlaying = true;
        }
      }
    };

    p.mouseReleased = () => {
      // Check if mouse is released inside the canvas
      if (
        p.mouseX >= 0 &&
        p.mouseX <= p.width &&
        p.mouseY >= 0 &&
        p.mouseY <= p.height
      ) {
        // Check if a note is currently playing before calling noteOff
        if (device && isNotePlaying) {
          noteOff(device, context, note);
          note = undefined; // Reset the note
          isNotePlaying = false;
        }
      }
    };

    // p.windowResized = () => {
    //     // Resize and center the canvas when the window is resized
    //     p.resizeCanvas(window.innerWidth / 2, window.innerHeight / 2).center('horizontal').position(50, 50);
    //   };
  };

  const myP5 = useMemo(() => new p5(sketch), []); // Create the p5 instance once
  window.addEventListener('beforeunload', function () {
    // Remove or hide the p5.js canvas here
    myP5.remove(); // Call a function to remove or hide the canvas
});
  return (
    <>
    <div className="buffer">
        <center>
          <a href="/">green milk </a>
        </center>
        </div>
     <center>this synth was made using RNBO. click in the box to make noise 👂 i dont think this works on mobile yet</center>
      <div ref={p5Canvas}></div>
    </>
  );
};

export default Patches;
