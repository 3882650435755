import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Container from '../Components/Container'
const HomePage = ({}) => {
  const publicURL = process.env.PUBLIC_URL;
    useEffect(() => {
        // Generate a random color
        // const randomColor = `#${Math.floor(Math.random()*16777215).toString(16)}`;
        // const isFirstLoad = localStorage.getItem('isFirstLoad');
        
        // // Apply the random color to the .rounded-link class
        //     if (isFirstLoad !== null) {
        //         const roundedLinks = document.querySelectorAll('.ascii-art');
        //         roundedLinks.forEach(link => {
        //           link.style.color = randomColor;
        //         });
        //     }
        //     if (isFirstLoad === null) {
        //         localStorage.setItem('isFirstLoad', 'false');
        //       }
      }, []); // Run this effect only once when the component mounts
  return (
<div className="home-container">
<header className="App-header">
<div className="buffer">
<div className="bordered-div">

   green milk
 
  </div>
  </div>
  <div className="ascii-art">
___<br />
.-' \\".<br />
/`    ;--:<br />
|     (  (_)==<br />
|_ ._ '.__.;<br />
\_/`--_---_(<br />
(`--(./-\\.)<br />
`|     _\\ |<br />
| \\  __ /<br />
/|  '.__/<br />
.'` \\     |_ <br />
'-__ / `- 
</div>
<p></p>
<div className="horizontal-container">
<Container title="patches" linkTo="/patches" />
<Container title="music" linkTo="/music" />
<Container title="ringtones" linkTo="/ringtones" />
</div>
</header>
</div>

  );
};

export default HomePage;
